@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@import './variables.scss';
@import './material.scss';
@import './modals.scss';
@import './helpers.scss';


/*****************************************    HACKS    **************************************************/
/* Required for 1st modal to be in focus after returning from second, stacked modal */
.modal {
	overflow: auto !important;
}
/* Required for toast notifications to be visible (name collision with toast and bootstrap) */
#toast-container > div {
    opacity:1;
}
/********************************************************************************************************/

.page {
	padding: 10px 10px 0 10px;
}

.grid-height {
	max-height: calc(100vh - (var(--menu-height) + 20px));
	min-height: 300px;
}
.tabbed-grid-height {
    max-height: calc(100vh - (var(--menu-height) + var(--tab-menu-height) + 20px));
    min-height: 300px;
}
.tabbed-grid-help-icon {
    max-height: calc(100vh - (var(--menu-height) + var(--tab-menu-height) + 40px));
    min-height: 300px;
}

.help-icon {
    margin: 5px;
    color: grey;
}
.help-icon:hover {
    color: var(--bootstrap-blue);
    cursor: pointer;
}

.btn-outline-primary {
    color: rgb(0, 76, 255);
	background: white;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.btn-outline-primary:hover {
    background: rgb(0, 76, 255);
	color: white;
}

.red {
    color: red;
}

.green {
    color: green;
}

.orange {
    color: orange;
}

.orangeBackground {
	background: orange;
}

.red:hover {
	color:white;
	background: red;
}

.green:hover {
	color: white;
	background: green;
}

.redNote {
    color: red;
}

.greenNote {
    color: green;
}

.redNote:hover {
    color: red;
}

.greenNote:hover {
    color: green;
}

.strikeclass {
    text-decoration: line-through;
}

.boldText {
    font-weight: bold;
}

.wrapText {
    white-space: pre-wrap;
    text-align: left;
    max-width: 100%; /* needed to make the wrapping of full width words work */
}

.error {
	margin: 20px 100px;
	text-align: center;
	padding:12px;
	border-style: solid;
	border-radius: 5px;
	border-color: rgb(253, 175, 175);
	border-width: 2px;
	color: #D8000C;
	background-color: #FFD2D2;
}
.error p {
	font-size:1em;
	vertical-align:middle;
	display: inline;
}

.error-small {
	margin: 0px 10px;
	padding:3px;
	text-align: center;
	border-style: solid;
	border-radius: 5px;
	border-color: rgb(253, 175, 175);
	border-width: 2px;
	color: #D8000C;
	background-color: #FFD2D2;
}
.error-small p {
	font-size:1em;
	vertical-align:middle;
	display: inline;
}

.success {
	margin: 20px 100px;
	text-align: center;
	padding:6px;
	border-style: solid;
	border-radius: 5px;
	border-color: rgb(213, 240, 166);
	border-width: 2px;
	color: #4F8A10;
	background-color: #DFF2BF;
}

.success h4 {
	vertical-align:middle;
	display: inline;
}

.globalTreeclass {
    height: 250px; font-size: .8em; width: 100%;
    border: 1mm;
	border-style: ridge;
    overflow: hidden;
    margin: 15px, 15px, 15px, 15px;
}

.redWhite {
	color:white;
	background: red;
}

.inlineClass {
	display: inline-block;
}

.modal-header {
  justify-content: space-between;
}
